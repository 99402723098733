<template>
  <mdb-container fluid>
    <Header></Header>
    <section class="mt-3">
      <div v-if="tests" class="px-4 d-flex " style="justify-content: center; flex-wrap: wrap;">
        <mdb-col v-for="(test, i) in tests" :key="i" class="w-25" style="text-align: center;">
          <img class="entry__thumb-icon" :src="`${basedomain}images/icon-link.svg`" />
          <h4>{{test.name}}</h4>
          <p>{{test.gives_skills.replace(' ', ', ')}}</p>
          <mdb-btn color="primary" @click="$router.push(`tests/${test.permalink}/attempt`)">Go do the test</mdb-btn>
        </mdb-col>
      </div>
      <mdb-spinner v-else multicolor size="sm" />
    </section>
  </mdb-container>
</template>

<script>
import Apicall from '@/libs/Apicall';
import Config from '@/libs/Config';
import Header from '@/components/Header.vue';
import {
  mdbBtn,
  mdbCol,
  mdbContainer,
  mdbSpinner,
} from 'mdbvue';

export default {
  components: {
    Header,
    mdbBtn,
    mdbCol,
    mdbContainer,
    mdbSpinner,
  },

  data() {
    return {
      tests: null,
      basedomain: Config.basedomain,
    };
  },

  mounted() {
    this.getLatestTest();
  },

  methods: {
    getLatestTest() {
      const api = new Apicall();

      api.call('GET', 'api/test', null).then((response) => {
        if (response.status === 'OK') {
          this.tests = response.data.testinfos;
        }
      });
    },
  },
};
</script>

<style>
  .entry__thumb {
    font-size: 1rem;
  }

  .entry__thumb-icon {
    width: 3.2rem;
    height: 3.2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 3rem 3rem;
  }
</style>
