<template>
  <mdb-container fluid>
    <Header></Header>
    <section class="mt-3" v-if="permalink !== null">
      <test-attempt-form :testPermalink="permalink" v-on:success="() => $router.push('/tests')"></test-attempt-form>
    </section>
  </mdb-container>
</template>

<script>
import { mdbContainer } from 'mdbvue';
import Header from '@/components/Header.vue';
import TestAttemptForm from '@/components/TestAttemptForm.vue';

export default {
  components: {
    mdbContainer,
    Header,
    TestAttemptForm,
  },

  data() {
    return {
      permalink: null,
    };
  },

  mounted() {
    const { permalink } = this.$route.params;

    this.permalink = permalink;
  },
};
</script>

<style>

</style>
