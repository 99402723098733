<template>
  <mdb-card>
    <mdb-card-header>
      Attempt for {{test !== null ? test.name : 'test'}}
    </mdb-card-header>
    <mdb-card-body>
      <form @submit="submitForm" @change="() => error = null">
        <mdb-row v-if="error !== null">
          <mdb-col sm="12">
            <mdb-alert color="danger">
              {{error}}
            </mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="successmsg !== null">
          <mdb-modal
            :show="showModal"
            @close="handleCloseModal"
            size="md"
          >
            <mdb-modal-header class="mt-3 mr-5 ml-5">
              <h4>{{successmsg}}</h4>
            </mdb-modal-header>
            <mdb-modal-body>
              <p>Thank you for your test attempt. We will send you an email with the results in the next 24 business hours.</p>
            </mdb-modal-body>
            <mdb-modal-footer>
              <button type="button" class="btn btn-success" @click="handleCloseModal">Ok</button>
            </mdb-modal-footer>
          </mdb-modal>
        </mdb-row>
        <div v-if="test">
          <mdb-row>
            <mdb-col sm="12">
              <mdb-input label="Email" v-model="email" type="email" required :readOnly="$store.state.userisloggued" />
            </mdb-col>
          </mdb-row>
          <mdb-row v-if="test.open === 'OPEN' && !$store.state.userisloggued">
            <mdb-col sm="12">
              <mdb-input label="First Name" v-model="name" type="text" required />
            </mdb-col>
          </mdb-row>
          <mdb-row v-if="test.open === 'OPEN' && !$store.state.userisloggued">
            <mdb-col sm="12">
              <mdb-input label="Last Name" v-model="lastName" type="text" required />
            </mdb-col>
          </mdb-row>
          <mdb-row v-if="test.open === 'OPEN' && !$store.state.userisloggued">
            <mdb-col sm="12">
              <mdb-input label="Phone Number" v-model="phoneNumber" type="text" required />
            </mdb-col>
          </mdb-row>
          <mdb-row class="mt-3">
            <mdb-col>
              <blockquote v-html="test.html"></blockquote>
            </mdb-col>
          </mdb-row>
          <hr />
          <mdb-row v-for="(question, i) in questions" :key="i">
            <mdb-col>
              <h3>Question #{{i+1}}</h3>
              <blockquote v-html="question.html"></blockquote>
              <div v-if="question.type === 'ABCD'">
                <ol class="visualizer">
                  <li v-for="(option, j) in question.placeholder.split('\n')" :key="j">
                    <mdb-input type="radio" :label="option" :name="`question-${i}`" :value="j" :id="`question-${i}-option-${j}`" />
                  </li>
                </ol>
              </div>
              <div v-if="question.type === 'MULTIPLE-ANSWER'">
                <ul class="visualizer">
                  <li v-for="(option, j) in question.placeholder.split('\n')" :key="j">
                    <mdb-input type="checkbox" :label="option" :name="`question-${i}[]`" :value="j" :id="`question-${i}-option-${j}`" />
                  </li>
                </ul>
              </div>
              <div v-if="question.type === 'SHORTTEXT'">
                <mdb-input type="text" label="Answer" :name="`question-${i}`" v-model="question.answer" required/>
              </div>
              <div v-if="question.type === 'LONGTEXT'">
                <mdb-input type="textarea" label="Answer" :name="`question-${i}`" v-model="question.answer" required/>
              </div>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col sm="12" style="text-align: end;">
              <mdb-btn v-if="!loadOnSubmit" type="submit" color="success">
                SUBMIT ATTEMPT
              </mdb-btn>
              <mdb-spinner v-else multicolor size="sm" />
            </mdb-col>
          </mdb-row>
        </div>
        <mdb-spinner v-else multicolor size="lg" />
      </form>
    </mdb-card-body>
  </mdb-card>
</template>

<script>
import {
  mdbAlert,
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbCol,
  mdbInput,
  mdbRow,
  mdbSpinner,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    mdbAlert,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbCol,
    mdbInput,
    mdbRow,
    mdbSpinner,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
  },

  props: {
    testPermalink: null,
  },

  data() {
    return {
      error: null,
      test: null,
      email: null,
      name: null,
      lastName: null,
      phoneNumber: null,
      successmsg: null,
      questions: [],
      loadOnSubmit: false,
      showModal: false,
    };
  },

  mounted() {
    this.getTest(this.testPermalink);

    if (this.$store.state.email) {
      this.email = this.$store.state.email;
    }
  },

  methods: {
    submitForm(event) {
      const request = {
        email: this.email,
        name: this.name,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        testid: this.test.testid,
        answers: this.questions.map((question) => {
          const { questionid, testid } = question;
          return {
            questionid,
            testid,
          };
        }),
      };
      const formData = new FormData(event.target);

      event.preventDefault();
      for (let i = 0; i < request.answers.length; i += 1) {
        const questionPlaceholderArray = this.questions[i].placeholder ? this.questions[i].placeholder.split('\n') : [];
        if (this.questions[i].type !== 'MULTIPLE-ANSWER' && this.questions[i].type !== 'ABCD') {
          request.answers[i].answer = formData.get(`question-${i}`);
        } else if (this.questions[i].type === 'ABCD') {
          request.answers[i].answer = questionPlaceholderArray[formData.get(`question-${i}`)];
        } else {
          const answers = [...formData.entries()].filter(([key]) => key.startsWith(`question-${i}[]`));
          const answerValues = [];

          answers.forEach(([key, value]) => {
            answerValues.push(questionPlaceholderArray[value]);
          });

          request.answers[i].answer = answerValues.join(',');
        }

        if (request.answers[i].answer === '' || request.answers[i].answer === null) {
            this.error = `Question #${i + 1} hasn't been answered`;
            return;
          }
      }

      const api = new Apicall();

      this.loadOnSubmit = true;

      api.call('POST', 'api/test/complete', request).then((response) => {
        this.loadOnSubmit = false;

        if (response.status === 'OK') {
          this.successmsg = 'Test completed';
          this.showModal = true;
          window.scrollTo(0, 0);
        } else {
          this.error = response.msg;
        }
      });
    },

    handleCloseModal() {
      this.showModal = false;
      this.$emit('success', this.test);
    },

    getTest(permalink) {
      const api = new Apicall();

      api.call('GET', `api/tests/${permalink}`, null).then((response) => {
        if (response.status === 'OK') {
          this.test = response.data.testinfos;
          this.questions = response.data.questioninfos;
        } else {
          this.error = response.data.msg;
        }
      });
    },
  },
};
</script>

<style scoped>
 ol {
  list-style-type: upper-alpha;
 }
 ul {
  list-style-type: none;
 }
</style>
